<template>
  <div class="pb-4 px-3 in-profile-invoices row">
    <b-alert class="col-12 text-right" variant="warning" show>
      <Info style="width: 22px;fill: #b8a676;"></Info> تمامی مبالغ به تومان می‌باشد.
    </b-alert>
    <b-table v-if="invoices" :items="invoices.data" :fields="fields" striped responsive="md">
      <template #cell(invoice_number)="row">
        <b class="text-primary">{{ row.item.invoice_number }}</b>
      </template>
      <template #cell(StatusTransaction)="row">
        <p v-if="row.item.StatusTransaction == '0' " class="p-2 badge bg-danger text-white text-center small rounded" >{{row.item.MessageStatusTransaction}}</p>
        <p v-else-if="row.item.StatusTransaction == '1'" class="p-2 badge bg-success text-center small rounded text-light" >{{row.item.MessageStatusTransaction}}</p>
      </template>
      <template #cell(status)="row">
        <InvoiceBadge :status="row.item.status.status"></InvoiceBadge>
      </template>
      <template #cell(SumTotalOrders)="row">
        {{ (row.item.SumTotalOrders ? row.item.SumTotalOrders : '-' ) | price}}
      </template>
      <template #cell(total_discount)="row">
        <span v-if="row.item.total_discount">{{ row.item.total_discount | price }}</span>
        <span v-else>ندارد</span>
      </template>
      <template #cell(AmountOrders)="row">
        {{ (row.item.AmountOrders ? row.item.AmountOrders : '-' ) | price}}
      </template>
      <template #cell(created_at)="row">
        {{row.item.created_at | persianDate}}
      </template>
      <template #cell(show_details)="row">
        <b-button :to="'/invoices/' + row.item.id" variant="primary">مشاهده جزئیات</b-button>
      </template>

    </b-table>
  </div>
</template>

<script>
import Info from "../svgs/Info";
import InvoiceBadge from "./InvoiceBadge";
export default {
  name: "ProfileInvoices",
  components: {InvoiceBadge, Info},
  props: {
    invoices: Object
  },
  data() {
    return {
      fields: [
        {
          key: 'invoice_number',
          label: 'شماره فاکتور'
        },
        {
          key: 'name',
          label: 'تحویل گیرنده'
        },
        {
          key: 'StatusTransaction',
          label: 'وضعیت پرداخت'
        },
        {
          key: 'status',
          label: 'وضعیت فاکتور'
        },
        {
          key: 'SumTotalOrders',
          label: 'جمع کل'
        },
        {
          key: 'total_discount',
          label: 'تخفیف'
        },
        {
          key: 'AmountOrders',
          label: 'مبلغ پرداختی'
        },
        {
          key: 'created_at',
          label: 'تاریخ'
        },
        {
          key: 'show_details',
          label: 'جزئیات'
        }
      ]
    }
  }
}
</script>

<style scoped>
  td *.badge {
    min-width: 100%;
  }


</style>
<style>
.in-profile-invoices td *.btn {
  font-size: 0.8rem;
  padding: 7px 8px;
}
</style>