<template>
  <div class="px-4 px-sm-0 py-2 pb-4 in-product-favorites row" v-if="favorites && favorites.data != ''">
    <div v-for="(favorite,index) in favorites.data" :key="index" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4 mb-md-4 mt-md-2">
      <article
          class="bg-white text-center position-relative discounted-products-item">
        <product :product="favorite.product" />
      </article>
    </div>
    <pagination :limit="2" :data="favorites" @pagination-change-page="loadItems" :disabled="disabled" class="justify-content-center">
                    <span slot="prev-nav">
                      <svg class="page-arrow mb-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                           viewBox="0 0 226 226" style=" fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                           stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                           font-family="none" font-weight="none" font-size="none" text-anchor="none"
                           style="mix-blend-mode: normal">
                          <path d="M0,226v-226h226v226z" fill="none"></path>
                          <g fill="#b2b2b2">
                            <path
                                d="M82.80781,169.67656c1.05938,1.05937 2.47187,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906l52.96875,-52.96875c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-52.96875,-52.96875c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0c-2.11875,2.11875 -2.11875,5.47344 0,7.41563l49.26094,49.26094l-49.26094,49.26094c-2.11875,2.11875 -2.11875,5.29688 0,7.41562z">
                            </path>
                          </g>
                        </g>
                      </svg>
                    </span>
      <span slot="next-nav">
                      <svg class="page-arrow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                           viewBox="0 0 226 226" style=" fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                           stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                           font-family="none" font-weight="none" font-size="none" text-anchor="none"
                           style="mix-blend-mode: normal">
                          <path d="M0,226v-226h226v226z" fill="none"></path>
                          <g fill="#b2b2b2">
                            <path
                                d="M143.19219,56.32344c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0l-52.96875,52.96875c-2.11875,2.11875 -2.11875,5.47344 0,7.41562l52.96875,52.96875c1.05937,1.05937 2.47188,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-49.26094,-49.26094l49.26094,-49.26094c2.11875,-2.11875 2.11875,-5.29687 0,-7.41563z">
                            </path>
                          </g>
                        </g>
                      </svg>
                    </span>
    </pagination>
  </div>
  <div v-else class="text-center my-4">
    <svg  width="30" height="30" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.761 20.8538C9.5904 19.5179 7.57111 17.9456 5.73929 16.1652C4.45144 14.8829 3.47101 13.3198 2.8731 11.5954C1.79714 8.25031 3.05393 4.42083 6.57112 3.28752C8.41961 2.69243 10.4384 3.03255 11.9961 4.20148C13.5543 3.03398 15.5725 2.69398 17.4211 3.28752C20.9383 4.42083 22.2041 8.25031 21.1281 11.5954C20.5302 13.3198 19.5498 14.8829 18.2619 16.1652C16.4301 17.9456 14.4108 19.5179 12.2402 20.8538L12.0051 21L11.761 20.8538Z"
                stroke="#444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.7393 7.05301C16.8046 7.39331 17.5615 8.34971 17.6561 9.47499" stroke="#444"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
    <h6 class="mt-3">محصول مورد علاقه ای وجود ندارد</h6>
  </div>
</template>

<script>
import Product from "../product";


export default {
  name: "ProfileFavorites",
  components: {Product},
  props: {
    favorites: Object
  },
  data() {
    return {
      disabled: false,
    }
  },

  methods: {
    async loadItems(page) {
      try {
        let response = await this.$axios.get('/api/favorites/list', {params: {page}});
        this.$emit('update:favorites', response.data.data)
      } catch (error) {
        this.$root.error_notification(error)
      }
    }
  }
}
</script>

<style>
.in-product-favorites .discounted-products-img {
  height: unset;
}
</style>
<style scoped>
.discounted-products-item {
  border: 1px solid #eaeaea;
  border-radius: 18px;
}
</style>
